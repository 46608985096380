import React, { Component } from "react";
import { RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import TagManager from "react-gtm-module";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import DOMPurify from 'dompurify';
import BaseComponent from "../../../Base/BaseComponent";
import MultiselectField from "../../../Base/MultiselectField";
import Checkbox from "../../../Base/Checkbox";

class FeatureFormsRetailerContactUs extends Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.state = {
      fields: props.fields.Fields,
      userEmailAddress: "",
      complete: false,
      submitted: false,
      successMessage: null,
      postData: [],
      show: false,
      showField: null,
      verifiedRecaptcha: false,
      expiredRecaptcha: false,
      load: false,
      recaptchaLoaded: false,
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      this.setState({ load: true });
    }, 1500);
  };

  recaptchaLoaded = () => {
    console.log("Successful Load Recaptcha");
    this.setState({ recaptchaLoaded: true });
  };

  recaptchaExpired = () => {
    this.setState({
      verifiedRecaptcha: false,
      expiredRecaptcha: true,
    });
  };
  recaptchaVerifyResponse = (response) => {
    if (response) {
      this.setState({
        verifiedRecaptcha: true,
        expiredRecaptcha: false,
      });
    } else {
      this.setState({ verifiedRecaptcha: false });
    }
  };

  validateField = (e, fields, fieldname, droplistValue) => {
    let { Name = null } = fields || {};
    this.state.fields.map((items) => {
      if (items.fields.Type.value === "Email Address Field") {
        this.setState({ userEmailAddress: items.fields.Value });
      }

      if (items.fields.Type.value === "Checkbox Field") {
        if (items.fields.IsRequired.value) {
          if (items.fields.Name.value === Name.value) {
            items.fields.Value = e.target.checked;
            items.fields.Valid = e.target.checked;
          }
        } else if (items.fields.Name.value === Name.value) {
          items.fields.Value = e.target.checked;
        } else {
          items.fields.Valid = true;
        }
      } else if (items.fields.Type.value === "DropList") {
        if (items.fields.IsRequired.value) {
          if (items.fields.Name.value === fieldname) {
            items.fields.Value = droplistValue;
            items.fields.Valid = droplistValue ? true : false;
          }
        } else if (items.fields.Name.value === fieldname) {
          items.fields.Value = droplistValue;
        } else {
          items.fields.Valid = true;
        }
      } else if (items.fields.Name.value === Name.value) {
        items.fields.Value = e.target.value;
        items.fields.Touched = true;

        if (items.fields.FormatValidation.value) {
          let expression = items.fields.FormatValidation.value;
          let re = new RegExp(expression);

          if (
            items.fields.IsRequired.value &&
            !re.test(String(e.target.value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else if (
            !items.fields.IsRequired.value &&
            e.target.value &&
            !re.test(String(e.target.value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else {
            items.fields.Valid = true;
          }
        } else {
          if (items.fields.IsRequired.value) {
            items.fields.Valid = e.target.value ? true : false;
          } else {
            items.fields.Valid = true;
          }
        }
      } else {
        if (items.fields.FormatValidation.value) {
          let expression = items.fields.FormatValidation.value;
          let re = new RegExp(expression);

          if (
            items.fields.IsRequired.value &&
            !re.test(String(items.fields.Value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else if (
            !items.fields.IsRequired.value &&
            items.fields.Value &&
            !re.test(String(items.fields.Value).toLowerCase())
          ) {
            items.fields.Valid = false;
          } else {
            items.fields.Valid = true;
          }
        } else {
          if (items.fields.IsRequired.value) {
            items.fields.Valid = items.fields.Value ? true : false;
          } else {
            items.fields.Valid = true;
          }
        }
      }

      this.setState({ fields: this.state.fields });
    });

    this.checkFields();
  };

  checkFields = () => {
    let data = this.state.fields;
    for (let i = 0; i < data.length; i++) {
      if (data[i].fields.Valid !== true) {
        this.setState({ complete: false });
        break;
      } else {
        this.setState({ complete: true });
      }
    }
  };

  handleSubmitBtn = (e) => {
    e.preventDefault();
    let contactUsWrapper = document.querySelector(
      ".retailer-contactus-wrapper"
    );
    let natureOfEnquiry = "";
    let formsWrapper = e.target,
      submitBtn = formsWrapper.querySelector(".submit-btn");
    const { SuccessMessage = null, FailedMessage = null } =
      this.props.fields || {};
    const { verifiedRecaptcha } = this.state;
    const recaptchaWrapper = document
      .getElementsByClassName("contactus-form")[0]
      .querySelector(".form-recaptcha");
    this.checkFields();
    this.setState({ submitted: true });

    setTimeout(() => {
      let invalidField = formsWrapper.querySelector(".form-control-error");
      if (invalidField) {
        let subNavWrapper = document.querySelector(".second-level-nagivation-container");
        if (subNavWrapper && window.outerWidth >= 992) {
          formsWrapper.scrollIntoView();
        } else {
          invalidField.scrollIntoView();
        }
      }
    }, 250);

    if (recaptchaWrapper) {
      if (!verifiedRecaptcha) {
        recaptchaWrapper.classList.add("is-required");
        return;
      }
    }
    if (!this.state.complete) return;

    submitBtn.setAttribute("disabled", "");

    let fields = [];
    const dataSource = this.props.rendering.dataSource || "";
    const url = `/api/sitecore/forms/contactus`;

    // key compose field data
    this.state.fields.map((field) => {
      if (field.fields.Type.value === "DropList") {
        fields.push({
          FieldName: field.fields.Name.value,
          Value: field.fields.Value ? field.fields.Value : "",
        });
        natureOfEnquiry = field.fields.Value ? field.fields.Value : ""; // for gtm
      } else if (field.fields.Type.value === "Checkbox Field") {
        fields.push({
          FieldName: field.fields.Name.value,
          Value: field.fields.Value ? field.fields.Value.toString() : "false",
        });
      } else {
        if (field.fields.Value) {
          fields.push({
            FieldName: field.fields.Name.value,
            Value: field.fields.Value,
          });
        }
      }
    });
    let postData = {
      ContactUsDetails: {
        ItemID: dataSource,
        UserEmailAddress: this.state.userEmailAddress,
        Fields: fields,
        SuccessMessage:
          SuccessMessage && SuccessMessage.value ? SuccessMessage.value : "",
        FailedMessage:
          FailedMessage && FailedMessage.value ? FailedMessage.value : "",
      },
    };

    axios({
      url,
      method: "POST",
      data: postData,
    })
      .then((response) => {
        if (response && response.data && response.data.message) {
          this.setState({
            successMessage: response.data.message,
            submitted: false,
          });
          TagManager.dataLayer({
            dataLayer: {
              event: "contactUsFormSubmit",
              natureOfEnquiry: natureOfEnquiry,
            },
            dataLayerName: "dataLayer",
          });
          contactUsWrapper.scrollIntoView();
        }
      })
      .catch((error) => {
        submitBtn.removeAttribute("disabled");
        console.error(error);
      });
  };

  setVisible = (fields) => {
    if (fields && fields.length > 0) {
      return "show";
    }
    return "hide";
  };

  handleToggle = (e, field) => {
    this.setState({ showField: field });
    e.target.focus();
    this.setState({ show: !this.state.show });
  };

  handleBlur = (e, field) => {
    this.setState({ showField: field });
    if (this.state.show) {
      setTimeout(() => {
        this.setState({ show: !this.state.show });
      }, 250);
    }
  };

  renderFields = (fieldList) => {
    return fieldList.map((items, index) => {
      const {
        Type,
        Name,
        IsRequired,
        Touched,
        Valid,
        Value,
        Values,
        Placeholder,
        RequiredValidationMessage,
      } = items.fields;

      let fieldLayout = "";

      switch (Type.value) {
        case "Heading Field":
          fieldLayout = (
            <div className="contactus-subheading" key={"fieldId" + index}>
              <Text field={Name} />
            </div>
          );
          break;
        case "Text Field":
        case "Email Address Field":
        case "Phone Number field":
          fieldLayout = (
            <div className="form-group" key={"fieldId" + index}>
              <input
                type="text"
                ref={this.inputField}
                id={Name.value}
                required={IsRequired.value ? true : false}
                onChange={(e) => this.validateField(e, items.fields)}
                onBlur={(e) => this.validateField(e, items.fields)}
                className={`form-control form-control-lg ${(this.state.submitted || Touched) &&
                  Valid !== true &&
                  (IsRequired.value || Valid === false)
                  ? "form-control-error"
                  : ""
                  }`}
                autoComplete="off"
              />
              <label
                className={`${IsRequired.value
                  ? "form-control-placeholder"
                  : "form-field-not-required"
                  } ${Value ? "form-field-hasValue" : ""}`}
                htmlFor={Name.value}
              >
                {Placeholder.value}
                {IsRequired.value && " *"}
              </label>
              {(this.state.submitted || Touched) &&
                Valid !== true &&
                (IsRequired.value || Valid === false) ? (
                <span>
                  <i className="icon icon-danger contactus-danger-icon">!</i>
                  <label className="form-control-validation">
                    {RequiredValidationMessage.value}
                  </label>
                </span>
              ) : null}
            </div>
          );
          break;
        case "DropList":
          fieldLayout = (
            <div
              className="form-group form-multiselect"
              key={"fieldId" + index}
            >
              <MultiselectField
                placeholder={Placeholder.value}
                optionList={Values}
                handleOnChangeMultiselect={(options) => {
                  this.validateField(null, items.fields, Name.value, options);
                }}
                isSubmitted={this.state.submitted}
                isRequired={IsRequired.value}
                errorMessage={RequiredValidationMessage.value}
              />
            </div>
          );
          break;
        case "Multi-Line Field":
          fieldLayout = (
            <div className="form-group form-textarea" key={"fieldId" + index}>
              <textarea
                id={Name.value}
                placeholder={Placeholder.value + (IsRequired.value ? " *" : "")}
                onChange={(e) => this.validateField(e, items.fields)}
                onBlur={(e) => this.validateField(e, items.fields)}
                className={
                  (this.state.submitted || Touched) &&
                    Valid !== true &&
                    IsRequired.value
                    ? "form-control-error"
                    : ""
                }
                autoComplete="off"
              />
              {(this.state.submitted || Touched) &&
                Valid !== true &&
                IsRequired.value ? (
                <span>
                  <i className="icon icon-danger contactus-danger-icon">!</i>
                  <label className="form-control-validation">
                    {RequiredValidationMessage.value}
                  </label>
                </span>
              ) : null}
            </div>
          );
          break;
        case "Checkbox Field":
          fieldLayout = (
            <Checkbox
              key={"fieldId" + index}
              id={Name.value}
              name={Placeholder.value}
              handleCheckboxClick={(event) => {
                this.validateField(event, items.fields);
              }}
              isSubmitted={this.state.submitted}
              isRequired={IsRequired.value}
              errorMessage={RequiredValidationMessage.value}
            />
          );
          break;
        default:
          fieldLayout = "";
      }

      return fieldLayout;
    });
  };

  render() {
    let message = null;
    const { verifiedRecaptcha, expiredRecaptcha, load } = this.state;
    const {
      EnableRecaptcha = null,
      PublicKey = null,
      PageSummary = null,
      Fields = null,
      PrivacyPolicy = null,
      SectionId = null,
      UseAsModule = null
    } = this.props.fields || {};

    let { RemoveDefaultMargin = "" } = this.props.params || {};

    let margin =
      RemoveDefaultMargin && RemoveDefaultMargin === "1" ? "" : "standard";

    if (this.state.successMessage) {
      message = (
        <div
          className="thanks-for-contacting"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.successMessage) }}
        />
      );
    }

    return (
      <BaseComponent
        setVisible={this.setVisible(Fields)}
        sectionId={SectionId && SectionId.value ? SectionId.value : ""}
        margin={margin}
      >
        <div className={`retailer-contactus-wrapper ${UseAsModule && UseAsModule.value ? "use-as-module" : ""}`}>
          {message ? (
            UseAsModule && UseAsModule.value ?
              <div className="container">
                {message}
              </div>
              : message
          ) : (
            <div className="container">
              <div className="contactus-description">
                <Text field={PageSummary} />
              </div>
              <form onSubmit={this.handleSubmitBtn} noValidate>
                <div className="contactus-form">
                  <div className="row">
                    <div className="col col-md-9">
                      {Fields &&
                        Fields.length > 0 &&
                        this.renderFields(Fields)}
                      {load &&
                        EnableRecaptcha &&
                        EnableRecaptcha.value &&
                        PublicKey &&
                        PublicKey.value && (
                          <div
                            className={
                              "form-group form-recaptcha " +
                              (verifiedRecaptcha
                                ? "verifiedRecaptcha "
                                : " ") +
                              (expiredRecaptcha ? "is-required" : " ")
                            }
                          >
                            <ReCAPTCHA
                              size="normal"
                              sitekey={
                                PublicKey && PublicKey.value
                                  ? PublicKey.value
                                  : "6LeqAfYUAAAAAGK1oZ1NzfVUFeC4kqJOe16XklGg"
                              }
                              onChange={this.recaptchaVerifyResponse}
                              onExpired={this.recaptchaExpired}
                              asyncScriptOnLoad={this.recaptchaLoaded}
                            />
                            <div className="form-control-validation">
                              <span>
                                <label>ReCaptcha is required</label>
                              </span>
                            </div>
                          </div>
                        )}
                      {PrivacyPolicy && PrivacyPolicy.value && (
                        <div className="form-group form-text">
                          <RichText field={PrivacyPolicy} />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="contactus-button">
                    <button
                      className="btn btn-large btn-light-primary submit-btn"
                      type="submit"
                    >
                      <span class="btn-label">{"Submit enquiry"}</span>
                      <i className="icon icon-chevron-up-2" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </BaseComponent>
    );
  }
}

export default FeatureFormsRetailerContactUs;
